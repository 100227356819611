import Styled from 'styled-components';
import DashboardGridImage1 from '../../../../assets/images/dashboard-grid-1.png';
import DashboardGridImage2 from '../../../../assets/images/dashboard-grid-2.png';

export const Layout = Styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  background-image: url(${DashboardGridImage1}), url(${DashboardGridImage2});
  background-position: top left, right bottom;
  background-repeat: no-repeat;
  padding: 50px 0;
`;

export const Container = Styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 600px)
`;

export const ButtonContainer = Styled.div`
  margin-top: 56px;
  align-self: flex-end;
`;

export const NotificationContainer = Styled.div`
  /* margin-top: 50px; */
`;

export const RedText = Styled.span`
  color: #BE0F34;
`;

export const TitleContainer = Styled.div`
  margin-bottom: 20px;
  align-self: flex-start;
  font-weight: bold;
  font-size: 1.333rem;
  line-height: 1.333rem;
  color: #BE0F34;
`;

export const TableContainer = Styled.div`
  margin-right: -30px;

  table {
    border-spacing: 0;
    width: 100%;

    thead {
      border-radius:10px 0 0 10px;
      th {
        text-align: left;
        background: #F2F2F2;
        word-break: initial;
        &:first-child {
          border-radius:10px 0 0 10px;
        }
        &:nth-last-child(2) {
          border-radius:0 10px 10px 0;
        }
        &:last-child {
          background: none;
        }
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    td {
      border-bottom: 1px solid rgba(100, 100, 100, 0.11);
      .state-badge {
        color: #fff;
        font-size: 0.8889rem;
        font-weight: 700;
        background: #FEA827;
        border-radius: 1.111rem;
        padding: 0.2778rem 0.5556rem;
        &.certified {
          background: #00CE5F;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 1.111rem;
      height: 56px;

      :last-child {
        border-right: 0;
        padding: 0;
        width: 0;
      }
      :nth-child(2) {
        min-width: 140px;
      }
    }
  }
`;

export const PaginationContainer = Styled.div`
  margin-top: 56px;
  align-self: flex-end;
  display: flex;
  
  div {
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7778rem;
    width: 1.667rem;
    height: 1.667rem;
    margin-left: 9px;
    cursor: pointer;

    &.current {
      background: #BE0F34;
      color: #fff;
    }
  }
`;
