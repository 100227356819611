import Styled from 'styled-components';

export const Layout = Styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background-color: #F2F2F2;
  border-radius: 8px;
  padding: 0 20px;
  width: 100%;

  svg {
    cursor: pointer;
  }
`;

export const TextContainer = Styled.div`
  color: var(--color, black);
`;