import Styled from 'styled-components';

export const Layout = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const StepIcon = Styled.div`
  margin-bottom: 15px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #BE0F34;
`;
export const TextContainer = Styled.div`
  font-size: 0.7778rem;
  color: #B1B1B1;
`;