import { useState, useEffect, useCallback } from 'react';
import Colorise from '../../../../components/atoms/colorize';
import TextUnderline from '../../../../components/atoms/text-undeline';
import Text from '../../../../components/atoms/text';
import Button from '../../../../components/atoms/button';
import PlantForm from './plantForm';
import { Layout, ActionsContainer, Action, ButtonContainer, FormContainer } from './style';
import { useTranslation } from 'react-i18next';
import certificationApi from '../../../../api/certification';
import GreenscoreProductApi from '../../../../api/greenscore-product';

const siteFormData = {
  type: 'cooperative',
  country: '',
  city: '',
  address: '',
  name: ''
};

const plantFormData = {
  codeSupplier: '',
  isBIO: false,
  isFair: false,
  movementReception: '',
  movementHarvestFrom: '',
  movementHarvestTo: '',
  greenscoreProductId: 0,
  greenscoreSiteId: 0
};

const StepForm2 = ({ duplicationData, certificationData, setLocalState, readOnly = false }) => {
  const { t } = useTranslation();

  let initialFormData;

  if (certificationData.state <= 2 && duplicationData) {
    initialFormData = duplicationData.certificationPlant.map(plant => ({
      ...plant
    }));
  }
  else if (certificationData?.certificationPlant?.length > 0) {
    initialFormData = certificationData.certificationPlant;
  }
  else {
    initialFormData = [];
  }

  const [formData, setFormData] = useState(initialFormData);
  const [greenscoreProduct, setGreenscoreProduct] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      // --> temporary
      const filteredData = formData;
      for (let i = 0; i < filteredData.length; ++i) {
        const site = await GreenscoreProductApi.createSite(
          filteredData[i].greenscoreProductId,
          filteredData[i].greenscoreSite
        );

        filteredData[i].greenscoreSiteId = site.data.id;
      }
      // <-- temporary
      if (certificationData?.state > 2) {
        await certificationApi.updatePlant(certificationData?.id, filteredData);
      } else {
        await certificationApi.createPlant(certificationData?.id, filteredData);
      }

      setLocalState(current => current + 1);
    } catch (error) {
      // todo show notification error
    }
  };

  useEffect(
    () => {
      GreenscoreProductApi.find({ 'where.type': 'plant', order: 'inciName' })
        .then(response => {
          setGreenscoreProduct(response?.data?.rows || []);
        });
    },
    []
  );

  const addNewForm = useCallback(
    () => {
      setFormData(formData => [
        ...formData,
        {
          ...plantFormData,
          greenscoreSite: { ...siteFormData }
        }
      ]);
    },
    []
  );

  useEffect(
    () => {
      if (formData.length <= 0 && !firstLoad) {
        addNewForm();
      }
      setFirstLoad(true);
    },
    [greenscoreProduct, formData.length, addNewForm, firstLoad]
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Layout>
          <FormContainer>
            {
              formData.map(
                (data, index) => {
                  return (
                    <PlantForm
                      formIndex={index}
                      greenscoreProduct={greenscoreProduct}
                      formData={data}
                      setFormData={setFormData}
                      key={index}
                      readOnly={readOnly}
                    />
                  );
                }
              )
            }
          </FormContainer>
          {!readOnly && <ActionsContainer>
            <Action onClick={addNewForm}>
              <Text style={{'--fontWeight': '700', marginRight: '0.2778rem'}}>[<Colorise>+</Colorise>]</Text>
              <TextUnderline size="18" color="#BE0F34">
                {t('certification.step2.instruction1')}
              </TextUnderline>
            </Action>
            <Text
              style={{
                '--fontSize': '0.6667rem',
                '--color': '#BE0F34',
                marginTop: '0.6667rem'
              }}
            >
              * {t('common.form.required')}
            </Text>
          </ActionsContainer>
          }
        </Layout>

        {!readOnly && <ButtonContainer>
            <Button type="submit">
              {t('common.form.next')}
            </Button>
          </ButtonContainer>
        }
      </form>
    </>
  );
};

export default StepForm2;