import Styled from 'styled-components';

export const Layout = Styled.span`
  position: relative;
`;
export const Content = Styled.div`
  position: absolute;
  background-color: white;
  padding: 10px 20px;
  font-weight: 200;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
`;