import { useState, useEffect } from 'react';
import { useQueryParams } from 'raviger';
import { Layout, Container } from './style';
import StepMenu from '../../../components/organisms/step-menu';
import StepTitle from '../../../components/molecules/step-title';
import StepForm1 from './stepForm1';
import StepForm2 from './stepForm2';
import StepForm3 from './stepForm3';
import StepForm4 from './stepForm4';
import certificationApi from '../../../api/certification';

const Certification = () => {
  const [query] = useQueryParams();
  // default value is undefined for ez check of duplication or not
  const [duplicationData, setDuplicationData] = useState();
  const [certificationData, setCertificationData] = useState();
  const [localState, setLocalState] = useState(1);
  const [loadingDuplication, setLoadingDuplication] = useState(true);
  const [loadingCertification, setLoadingCertification] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const props = { duplicationData, certificationData, setLocalState };

  const renderStepForm = step => {
    switch (step) {
      case 1:
        return <StepForm1 {...props} />;
      case 2:
        return <StepForm2 {...props} />;
      case 3:
        return <StepForm3 {...props} />;
      case 4:
        return <StepForm4 {...props} />;
      case 5:
        return <StepForm4 {...props} />;
      default:
        return null;
    }
  };

  useEffect(
    () => {
      const loadingRoutine = async () => {
        setLoadingDuplication(true);

        if (query.duplication) {
          const { data } = await certificationApi.findOne(query.duplication);

          setDuplicationData(data);
        }

        setLoadingDuplication(false);
      };

      loadingRoutine();
    },
    [query.duplication]
  );

  useEffect(
    () => {
      const loadingRoutine = async () => {
        setLoadingCertification(true);

        if (query.id) {
          const { data } = await certificationApi.findOne(query.id);
          if (firstLoad)
            setLocalState(data.state);
          setCertificationData(data);
        }

        setLoadingCertification(false);
      };

      loadingRoutine();
    },
    [query.id, localState, firstLoad]
  );

  useEffect(
    () => {
      setFirstLoad(false);
    },
    [setFirstLoad]
  );

  return (
    <Layout>
      <Container>
        {
          !loadingCertification && !loadingDuplication
          && (
            <>
              <StepMenu stepNumber={localState} stepReach={certificationData?.state} handleStepClick={setLocalState} />
              <StepTitle stepNumber={localState} certificationData={certificationData} />
              {renderStepForm(localState)}
            </>
          )
        }
      </Container>
    </Layout>
  );
};


export default Certification;