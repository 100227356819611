import Styled from 'styled-components';

export const Layout = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionsContainer = Styled.div`
  margin-top: 25px;

`;

export const Action = Styled.div`
  display: flex;
  margin-top: 1.111rem;
  cursor: pointer;
`;

export const ButtonContainer = Styled.div`
  width: 30%;
  margin: 25px 40px 51px auto;
`;

export const FormContainer = Styled.div``;