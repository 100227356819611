import { Overlay, Content, Header, Body, Title, Close } from './style';
import Colorise from '../../atoms/colorize';

const Modal = ({ showModal, handleClose, title, children}) => {
  if (!showModal) {
    return null;
  }
  return (
    <Overlay>
      <Content>
        <Header>
          <Title>{ title }</Title>
          <Close onClick={handleClose}>
            [<Colorise>x</Colorise>]
          </Close>
        </Header>
        <Body>
          { children }
        </Body>
      </Content>
    </Overlay>
  );
};

export default Modal;