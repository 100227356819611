const EN = {
  translation: {
    common: {
      welcome: 'Welcome to the Clarins Group <1>traceability platform</1>.',
      connection: 'Login',
      mailAddress: 'E-mail address',
      password: 'Password',
      certificate: 'Certify a batch of raw materials',
      form: {
        yes: 'Yes',
        no: 'No',
        next: 'Next',
        validate: 'Validate',
        toPeriod: 'to',
        required: 'Fields required',
        selectOption: 'Select an option'
      }
    },
    navBar: {
      settings: 'Settings',
      disconnect: 'Logout'
    },
    signIn: {
      connect: 'Login',
      noAccount: 'No account? <1>Click here</1> to create one'
    },
    dashboardHomeList: {
      welcome: 'Welcome <1>{{currentUserName}}</1>, <2>{{newsNumber}} new raw materials</2> have been added since your last visit.',
      tableHeader: {
        certificatedBy: 'Certified by',
        status: 'Status',
        commercialName: 'Commercial name',
        quantity: 'Quantity',
        batchCode: 'Shipped batch number',
        creationDate: 'Creation date',
        certificationDate: 'Certification date'
      },
      status: {
        pending: 'Pending',
        certified: 'Certified'
      },
      dropDown: {
        resume: 'Resume',
        details: 'Details',
        duplicate: 'Duplicate',
        downloadPDF: 'Download as PDF',
        downloadExcel: 'Download as Excel'
      }
    },
    dashboardHomeEmpty: {
      welcome: 'Hello {{currentUserName}},',
      notYetMaterial: 'You have not yet certified any raw material.',
      dontWait: 'Do not wait !'
    },
    certification: {
      step1: {
        menuTitle: '01. General information',
        firstTitle: 'Certify a batch of raw materials',
        secondTitle: 'Raw material information',
        form: {
          commercialName: {
            label: 'Commercial name'
          },
          bioCertified: {
            label: 'Raw material is certified organic ?'
          },
          batchNumber: {
            label: 'Batch number of raw material'
          },
          internalCode: {
            label: 'CLARINS internal code'
          },
          veganCertified: {
            label: 'Raw material is certified VEGAN ?'
          },
          prodactionDate: {
            label: 'Production date of the extract'
          },
          internalDesignation: {
            label: 'CLARINS internal designation'
          },
          fairCertified: {
            label: 'Raw material is certified fair trade ?'
          },
          productionSite: {
            label: 'Production site name'
          },
          category: {
            label: 'CLARINS category'
          },
          fairPrime: {
            label: 'Contrat Prime Equitable CLARINS'
          },
          productionAddress: {
            label: 'Production site address'
          }
        }
      },
      step2: {
        firstTitle: 'Certify the associated plant lots',
        menuTitle: '02. Certification of the plants',
        plantTitle: 'Plant: ',
        originTitle: 'Harvest geographical origin',
        instruction1: 'Declare another batch of plant for this batch of material or a multiple geographical origin for this same batch of plant.',
        addNewBatch: 'Add a new batch of plants / other origins',
        addNewPlant: 'Add a new plant',
        deleteBatch: 'Remove this batch',
        form: {
          productName: {
            label:'INCI of related plants'
          },
          batchNumber: {
            label: 'Plant batch number – N/A if not applicable'
          },
          bioCertified: {
            label: 'Plant descending of organic agriculture ?'
          },
          receptionDate : {
            label: 'Date of reception'
          },
          fairCertified: {
            label: 'Plant from the fair trade certificated commodity chain ?'
          },
          harvestPeriod: {
            label: 'Harvest period',
            tooltip: 'If you wish to declare the harvest date for the plant of interest, please indicate the same date in the two fields below.'
          },
          origin: {
            name: {
              label: 'Name of the partner or cooperative'
            },
            country: {
              label: 'Country'
            },
            region: {
              label: 'Region'
            },
            address: {
              label: 'Address'
            }
          }
        }
      },
      step3: {
        menuTitle: '03. Date & quantity',
        firstTitle: 'Certify a batch of raw materials',
        secondTitle: 'Date & quantity',
        form: {
          movementExpedition: {
            label: 'Confirm the shipping date'
          },
          batchPurchaseOrder: {
            label: 'Purchase order number'
          },
          batchQuantity: {
            label: 'Confirm the quantity (Kg)'
          }
        }
      },
      step4: {
        menuTitle: '04. Summary & validation',
        firstTitle: 'Certify a batch of raw materials',
        secondTitle: 'Summary & validation',
        titleStep1: 'General information',
        titleStep2: 'Certify the associated plant lots',
        titleStep3: 'Date & quantity'
      },
      step5: {
        firstTitle: 'Certification details',
        secondTitle: 'Summary'
      },
      addProductionForm: {
        addAddress: 'Add a production site',
        name: 'Name of the production site',
        country: 'Country',
        region: 'City',
        address: 'Address',
        text: 'Please also register this new production site address in Green Score.'
      },
      addCooperativeForm: {
        addAddress: 'Add a partner or a cooperative',
        country: 'Native country',
        name: 'Name & address of partner or cooperative',
        region: 'Harvest location region',
        address: 'Harvest location address',
        text: 'Please also register this new partner or a cooperative in Green Score.'
      },
      validation: {
        text: 'What would you like to do ?',
        backButton: 'Back to dashboard',
        successNotification: 'Your certification has been registered'
      }
    }
  }
};

export default EN;