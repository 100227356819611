import InputTextLabeled from '../../../../components/molecules/input-text-labeled';
import Button from '../../../../components/atoms/button';
import { Layout, InputGroup, ButtonContainer, Text} from './style';
import { useTranslation } from 'react-i18next';
import { useInput } from '../../../../hooks';

const AddProductionForm = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const initialFormData = {
    name: '',
    type: 'factory',
    address: '',
    city: '',
    country: ''
  };

  const [formData,, setFormDataOnChange] = useInput(initialFormData);

  return (
    <Layout>
      <InputGroup>
        <InputTextLabeled
          value={formData.name}
          label={t('certification.addProductionForm.name')}
          isMandatory
          type="text"
          name="name"
          onChange={setFormDataOnChange}
        />
      </InputGroup>
      <InputGroup>
        <InputTextLabeled
          value={formData.country}
          label={t('certification.addProductionForm.country')}
          isMandatory
          type="text"
          name="country"
          onChange={setFormDataOnChange}
        />
      </InputGroup>
      <InputGroup>
        <InputTextLabeled
          value={formData.city}
          label={t('certification.addProductionForm.region')}
          type="text"
          name="city"
          onChange={setFormDataOnChange}
        />
      </InputGroup>
      <InputGroup>
        <InputTextLabeled
          value={formData.address}
          label={t('certification.addProductionForm.address')}
          type="text"
          name="address"
          onChange={setFormDataOnChange}
        />
      </InputGroup>

      <Text>{t('certification.addProductionForm.text')}</Text>

      <ButtonContainer>
        <Button onClick={handleSubmit(formData)}>
          {t('common.form.validate')}
        </Button>
      </ButtonContainer>
    </Layout>
  );
};

export default AddProductionForm;