import Styled from 'styled-components';

export const Layout = Styled.div`
  margin-top: 40px;
`;

export const InputGroup = Styled.div``;

export const MultiInputGroup = Styled.div`
  display: grid;
  grid-template-columns: 1fr 2.667rem 1fr;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
`;

export const SelectContainer = Styled.div``;

export const BatchForm = Styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 344px;
  row-gap: 51px;
  margin: 50px 0 40px 0;
`;

export const OriginForm = Styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  column-gap: 69px;

  label {
    width: 100%;
  }
  /* > div {
    display: flex;
    align-items: flex-end;
    &:first-child {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
    }
  } */
`;


export const OriginForm2 = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  > div {
    display: flex;
    align-items: flex-end;
    margin-left: 69px;
    &:first-child {
      margin: 0;
    }
    > div:nth-child(2) {
      margin-left: 10px;
    }
  }
`;

export const Title = Styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
  font-size: 1.333rem;
`;

export const SecondTitle = Styled.span`
  font-size: 1.556rem;
  font-weight: bold;
  line-height: 1.556rem;
  color: #BE0F34;
`;