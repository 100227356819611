import { Layout } from './style';
import Text from '../../atoms/text';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

const Notification = ({ children, closeNotification }) => {
    return (
        <Layout>
            <Text>{children}</Text>
            <CloseIcon onClick={closeNotification} />
        </Layout>
    );
};

export default Notification;