import Label from '../../atoms/label';
import Colorize from '../../atoms/colorize';
import Tooltip from '../../molecules/tooltip';
import { FiInfo } from 'react-icons/fi';

const MultipleInputLabeled = ({ label, tooltip, isMandatory, children }) => {
  return (
    <Label>
      {label}&nbsp;
      {isMandatory && <Colorize>*</Colorize>}&nbsp;
      {
        tooltip && (
          <Tooltip
            parent={() => <FiInfo color="#BE0F34" size="16px" />}
            children={() => <>{tooltip}</>}
          />
        )
      }
      { children }
    </Label>
  );
};

export default MultipleInputLabeled;