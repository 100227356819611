import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    font-size: 18px;
  }

  body {
    height: 100%;
  }

  #root {
    height: 100%;
  }

  .Toastify__toast-container--top-center {
    top: 150px;
    min-width: 400px;
    
    font-size: 0.7778rem;

    .Toastify__toast--default {
      color: initial;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;

      button {
        align-self: center;
      }

      svg {
        margin-right: 12px;
      }
    }
    .Toastify__progress-bar--default {
      background: #27AE60;
    }
  }
`;

export default GlobalStyle;