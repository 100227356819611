import { Layout } from './style';
import StepMenuEntry from '../../molecules/step-menu-entry';
import { useTranslation } from 'react-i18next';


const StepsMenu = props => {
  const { t } = useTranslation();

  if (props.stepNumber >= 5) return null;

  return (
    <Layout>
      <StepMenuEntry step={1} text={t('certification.step1.menuTitle')} {...props} />
      <StepMenuEntry step={2} text={t('certification.step2.menuTitle')} {...props} />
      <StepMenuEntry step={3} text={t('certification.step3.menuTitle')} {...props} />
      <StepMenuEntry step={4} text={t('certification.step4.menuTitle')} {...props} />
    </Layout>
  );
};


export default StepsMenu;