import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Layout, MessageContainer, TextContainer, CertificateContainer } from './style';
import Colorize from '../../../../components/atoms/colorize';
import Text from '../../../../components/atoms/text';
import Button from '../../../../components/atoms/button';
import DashboardHomeImage from '../../../../assets/images/dashboard-home.png';
import { navigate } from 'raviger';

const Empty = () => {
  const { t } = useTranslation();

  const currentUserName = useSelector(state => `${state.user.firstName} ${state.user.lastName}`);

  const goToCertification = () => {
    navigate('/dashboard/certification');
  };

  return (
    <Layout
      style={{
        '--backgroundImage': `url(${DashboardHomeImage})`
      }}
    >
      <MessageContainer>
        <TextContainer>
          <Text
            style={{
              '--fontWeight': '400',
              '--fontSize': '46px',
              '--textAlign': 'center'
            }}
          >
            <Trans
              t={t}
              i18nKey="dashboardHomeEmpty.welcome"
              values={{ currentUserName: currentUserName }}
            />
          </Text>
        </TextContainer>
        <TextContainer>
          <Text
            style={{
              '--fontWeight': '300',
              '--fontSize': '32px',
              '--textAlign': 'center'
            }}
          >
            <Trans
              i18nKey="common.welcome"
              t={t}
            >
              Bienvenue sur <Colorize>l’outil de traçabilité</Colorize> du Groupe Clarins.
            </Trans>
          </Text>
        </TextContainer>
      </MessageContainer>
      <CertificateContainer>
        <Button onClick={goToCertification}>{t('common.certificate')}</Button>
      </CertificateContainer>
    </Layout>
  );
};

export default Empty;