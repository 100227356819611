import Styled from 'styled-components';

const Text = Styled.div`
  font-weight: var(--fontWeight);
  font-size: var(--fontSize);
  text-align: var(--textAlign);
  line-height: var(--lineHeight);
  color: var(--color);
`;

export default Text;