import { useState, useEffect } from 'react';
import { Container, DropAction, DropMenu } from './style';

const Dropdown = ({ parent: Parent, children: Children, anchor = 'left' }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const close = () => setDropdownOpen(false);

  useEffect(() => {
    if (dropdownOpen) {
      window.addEventListener('click', close);
    }
    return () => {
      window.removeEventListener('click', close);
    };
  }, [dropdownOpen]);

  return (
    <Container>
      <DropAction onClick={toggle}>
        <Parent />
      </DropAction>
      {
        dropdownOpen
        && <DropMenu anchor={anchor}>
          <Children />
        </DropMenu>
      }
    </Container>
  );
};

export default Dropdown;