import { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { useQueryParams } from 'raviger';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { navigate } from 'raviger';
import { Layout, Container, ButtonContainer, NotificationContainer, TitleContainer, TableContainer, PaginationContainer } from './style';
import Colorize from '../../../../components/atoms/colorize';
import Notification from '../../../../components/molecules/notification';
import Button from '../../../../components/atoms/button';
import Dropdown from '../../../../components/molecules/dropdown';
import { ReactComponent as DropdownIcon } from '../../../../assets/icons/dropdown.svg';
import { ReactComponent as ViewIcon } from '../../../../assets/icons/view.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
// import { ReactComponent as PdfIcon } from '../../../../assets/icons/generate-pdf.svg';
// import { ReactComponent as XlsIcon } from '../../../../assets/icons/xls.svg';
import { ReactComponent as AgricultureIcon } from '../../../../assets/icons/agriculture.svg';

const CertificationsList = ({ certifications, pagesNumber, currentPage }) => {
  const { t } = useTranslation();

  const currentUserName = useSelector(state => `${state.user.firstName} ${state.user.lastName}`);

  const data = useMemo(
    () => certifications, [certifications]
  );

  const columns = useMemo(
    () => [
      {
        Header: t('dashboardHomeList.tableHeader.certificatedBy'),
        accessor: 'user'
      },
      {
        Header: t('dashboardHomeList.tableHeader.status'),
        accessor: 'state',
        Cell: ({ cell }) => (
          <span className={cell.row.values.state < 5 ? 'state-badge' : 'state-badge certified'}>
            {cell.row.values.state < 5 ? t('dashboardHomeList.status.pending'): t('dashboardHomeList.status.certified')}
          </span>
        )
      },
      {
        Header: t('dashboardHomeList.tableHeader.commercialName'),
        accessor: 'commercialName'
      },
      {
        Header: t('dashboardHomeList.tableHeader.quantity'),
        accessor: 'quantity',
        Cell: ({ value }) => (`${value} kg`)
      },
      {
        Header: t('dashboardHomeList.tableHeader.batchCode'),
        accessor: 'codeSupplier'
      },
      {
        Header: t('dashboardHomeList.tableHeader.creationDate'),
        accessor: 'createdAt'
      },
      {
        Header: t('dashboardHomeList.tableHeader.certificationDate'),
        accessor: 'certifiedAt'
      },
      {
        id: 'dropdown',
        Header: '',
        accessor: row => row,
        // accessor: 'id',
        Cell: ({ value }) => {
          const { id, state } = value;
          return (
            <Dropdown
              parent={DropdownIcon}
              children={
                () => (
                  <>
                    <li onClick={handleClickView(id)}>
                      {state < 5 ? t('dashboardHomeList.dropDown.resume') : t('dashboardHomeList.dropDown.details') } <ViewIcon />
                    </li>
                    {
                      <li onClick={handleClickDuplicate(id)}>
                        {t('dashboardHomeList.dropDown.duplicate')} <CopyIcon />
                      </li>
                    }
                    {/* <li>
                      {t('dashboardHomeList.dropDown.downloadPDF')} <PdfIcon />
                    </li>
                    <li>
                      {t('dashboardHomeList.dropDown.downloadExcel')} <XlsIcon />
                    </li> */}
                  </>
                )
              }
            />
          );
        }
      }
    ], [t]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data });

  const handleClickView = value => () => {
    // todo handle menu click
    navigate(`/dashboard/certification?id=${value}`);
  };

  const handleClickDuplicate = value => () => {
    navigate(`/dashboard/certification?duplication=${value}`);
  };

  const closeNotification = () => {
    setShowNotif(false);
  };

  const goPage = page => {
    setQuery({page});
  };

  const [, setQuery] = useQueryParams();
  const [showNotif, setShowNotif] = useState(true);
  const [newsNumber] = useState(2);

  const pages = useMemo(() => {
    const pages = [];
    for (var i = 0; i < pagesNumber; i++) {
      pages.push(i + 1);
    }
    return pages;
  }, [pagesNumber]);

  const goToCertification = () => {
    navigate('/dashboard/certification');
  };

  return (
    <Layout>
      <Container>
        <NotificationContainer>
          {
            showNotif && newsNumber > 0 && <Notification closeNotification={closeNotification} style={{'--marginTop': '50px'}}>
              <Trans
                t={t}
                i18nKey="dashboardHomeList.welcome"
                values={{ currentUserName: currentUserName, newsNumber: newsNumber }}
                components={{ 1: <strong />, 2: <Colorize /> }}
              />
            </Notification>
          }
        </NotificationContainer>
        <ButtonContainer>
          <Button onClick={goToCertification}>
            <AgricultureIcon fill="#fff" /> {t('common.certificate')}
          </Button>
        </ButtonContainer>

        <TitleContainer>
          {t('common.certificate')}
        </TitleContainer>
        <TableContainer>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.value !== null ? cell.render('Cell') : <div>-</div>}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableContainer>
        <PaginationContainer>
          { pages.map(page => <div onClick={() => goPage(page)} className={page === currentPage ? 'current' : ''} key={page}>{page}</div>) }
        </PaginationContainer>
      </Container>
    </Layout>
  );
};

export default CertificationsList;