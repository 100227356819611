import Styled from 'styled-components';

export const Layout = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #C4C4C4, 50%;
  background-image: var(--backgroundImage);
`;
export const MessageContainer = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 78px;
`;
export const TextContainer = Styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;
export const CertificateContainer = Styled.div``;