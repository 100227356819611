const FR = {
  translation: {
    common: {
      welcome: 'Bienvenue sur <1>l’outil de traçabilité</1> du Groupe Clarins.',
      connection: 'Connexion',
      mailAddress: 'Adresse email',
      password: 'Mot de passe',
      certificate: 'Certifier un lot de matières premières',
      form: {
        yes: 'Oui',
        no: 'Non',
        next: 'Suivant',
        validate: 'Valider',
        toPeriod: 'au',
        required: 'Champs obligatoires',
        selectOption: 'Sélectionnez une option'
      }
    },
    navBar: {
      settings: 'Paramètres',
      disconnect: 'Se déconnecter'
    },
    signIn: {
      connect: 'Se connecter',
      noAccount: 'Pas de compte ? <1>Cliquez ici</1> pour en créer un'
    },
    dashboardHomeList: {
      welcome: 'Bienvenue <1>{{currentUserName}}</1>, <2>{{newsNumber}} nouvelles matières premières</2> ont été ajoutés depuis votre dernière visite.',
      tableHeader: {
        certificatedBy: 'Certifié par',
        status: 'Statut',
        commercialName: 'Nom commercial',
        quantity: 'Quantité',
        batchCode: 'N° de lot expédié',
        creationDate: 'Date de création',
        certificationDate: 'Date de certification'
      },
      status: {
        pending: 'En attente',
        certified: 'Certifié'
      },
      dropDown: {
        resume: 'Reprendre',
        details: 'Détails',
        duplicate: 'Dupliquer',
        downloadPDF: 'Télécharger en PDF',
        downloadExcel: 'Télécharger en Excel'
      }
    },
    dashboardHomeEmpty: {
      welcome: 'Bonjour {{currentUserName}},'
    },
    certification: {
      step1: {
        menuTitle: '01. Informations générales',
        firstTitle: 'Certifier un lot de matières premières',
        secondTitle: 'Informations matière première',
        form: {
          commercialName: {
            label: 'Nom commercial'
          },
          bioCertified: {
            label: 'Matière première certifiée biologique ?'
          },
          batchNumber: {
            label: 'Numéro de lot de la matière première'
          },
          internalCode: {
            label: 'Code interne CLARINS'
          },
          veganCertified: {
            label: 'Matière première certifiée végan ?'
          },
          prodactionDate: {
            label: 'Date de production de la matière première'
          },
          internalDesignation: {
            label: 'Désignation interne CLARINS'
          },
          fairCertified: {
            label: 'Matière première certifiée équitable ?'
          },
          productionSite: {
            label: 'Nom du site de production'
          },
          category: {
            label: 'Catégorie CLARINS'
          },
          fairPrime: {
            label: 'Contrat Prime Equitable CLARINS'
          },
          productionAddress: {
            label: 'Adresse du site de production'
          }
        }
      },
      step2: {
        firstTitle: 'Certifier les lots de plantes associés',
        menuTitle: '02. Certification de lot plantes',
        plantTitle: 'Plante: ',
        originTitle: 'Origine géographique de la récolte',
        instruction1: 'Déclarer un autre lot de plante pour ce lot de MP ou une origine géographique multiple pour ce même lot de plante.',
        addNewBatch: 'Ajouter un nouveau lot de plante / des autres origines',
        addNewPlant: 'Ajouter une nouvelle plante',
        deleteBatch: 'Supprimer ce lot',
        form: {
          productName: {
            label:'INCI de la plante d’intêret'
          },
          batchNumber: {
            label: 'Numéro de lot plante – N/A si c’est non applicable'
          },
          bioCertified: {
            label: 'Plante issue d\'agriculture BIO ?'
          },
          receptionDate : {
            label: 'Date de réception'
          },
          fairCertified: {
            label: 'Plante issue de filière certifiée équitable ?'
          },
          harvestPeriod: {
            label: 'Période de récolte',
            tooltip: 'Si vous souhaitez déclarer la date de récolte pour la plante d’intérêt, merci d’indiquer la même date dans les deux champs ci-dessous.'
          },
          origin: {
            name: {
              label: 'Nom & adresse du partenaire ou de la coopérative'
            },
            address: {
              label: 'Adresse du lieu de récolte'
            },
            region: {
              label: 'Région du lieu récolte'
            },
            country: {
              label: 'Pays d\'origine'
            }
          }
        }
      },
      step3: {
        menuTitle: '03. Date & quantité',
        firstTitle: 'Certifier un lot de matières premières',
        secondTitle: 'Date & quantité',
        form: {
          movementExpedition: {
            label: 'Confirmez la date d’expédition'
          },
          batchPurchaseOrder: {
            label: 'Numéro d’OA'
          },
          batchQuantity: {
            label: 'Confirmez la quantité (Kg)'
          }
        }
      },
      step4: {
        menuTitle: '04. Résumé & validation',
        firstTitle: 'Certifier un lot de matières premières',
        secondTitle: 'Résumé & validation',
        titleStep1: 'Informations générales',
        titleStep2: 'Certification de vos plantes',
        titleStep3: 'Date & Quantité'
      },
      step5: {
        firstTitle: 'Détails d\'un lot de matières premières',
        secondTitle: 'Résumé'
      },
      addProductionForm: {
        title: 'Ajouter un site de production',
        name: 'Nom du site de production',
        country: 'Pays',
        region: 'Ville',
        address: 'Adresse',
        text: 'Merci de déclarer également ce nouveau site de production dans Green Score.'
      },
      addCooperativeForm: {
        title: 'Ajouter un partenaire ou une coopérative',
        country: 'Pays d\'origine',
        name: 'Nom & adresse du partenaire ou de la coopérative',
        region: 'Région du lieu récolte',
        address: 'Adresse du lieu de récolte',
        text: 'Merci de déclarer également cette nouvelle origine géographique dans Green Score.'
      },
      validation: {
        text: 'Que souhaitez-vous faire ?',
        backButton: 'Revenir au dashboard',
        successNotification: 'Votre certification a bien été enregistrée'
      }
    }
  }
};

export default FR;