import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../../redux/language';
import { Layout, Flag } from './style';
import Dropdown from '../dropdown';
import France from '../../../assets/flags/France.png';
import England from '../../../assets/flags/England.png';

// we can hardcode language here because it won't get translated
const languages = [
  {
    name: 'Français',
    short: 'fr',
    flag: France
  },
  {
    name: 'Anglais',
    short: 'en',
    flag: England
  }
];

const LanguagePicker = () => {
  const dispatch = useDispatch();
  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
  const languageShort = useSelector(state => state.language.short);

  const setLanguage = language => {
    dispatch(changeLanguage(language.short));
  };

  useEffect(
    () => {
      setCurrentLanguage(languages.find(language => language.short === languageShort) || 'fr');
    },
    [languageShort]
  );

  return (
    <Layout>
      <Dropdown
        parent={() => (
          <>
            <Flag src={currentLanguage.flag} />
          </>
        )}
        children={() => (
          <>
            {
              languages.map(language => <li key={language.short} onClick={() => setLanguage(language)}>{language.name}<Flag src={language.flag} /></li>)
            }
          </>
        )}
        anchor="right"
      />
    </Layout>
  );
};

export default LanguagePicker;