import Home from './home';
import { Redirect } from 'raviger';
import Certification from './certification';
import Signout from './signout';
import Validation from './certification/validation';

const router = {
  '/': () => <Redirect to="/dashboard/home" />,
  '/home': () => <Home />,
  '/certification': () => <Certification />,
  '/validation': () => <Validation />,
  '/signout': () => <Signout />
};

export default router;

export const basePath = '/dashboard';