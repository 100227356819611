import Label from '../../atoms/label';
import InputText from '../../atoms/input-text';
import Colorize from '../../atoms/colorize';

const InputTextLabeled = ({ label, isMandatory, ...inputProps }) => {
  return (
    <Label>
      {label} {isMandatory && <Colorize>*</Colorize>}
      <InputText {...inputProps} />
    </Label>
  );
};

export default InputTextLabeled;