const initialState = {
  short: 'fr'
};

const user = (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        ...payload
      };
    case 'CHECK_LOCAL_LANGUAGE':
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

export default user;