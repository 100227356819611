import Styled from 'styled-components';

export const Container = Styled.div`
  position: relative;
`;

export const DropAction  = Styled.div`
  cursor: pointer;
`;

export const DropMenu = Styled.div`
  position: absolute;
  background-color: #fff;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  z-index: 11;
  list-style: none;
  padding: 0.6667rem 0;
  margin: 0;
  font-size: 0.8889rem;

  margin-top: 10px;
  ${
    props => props.anchor === 'right' && 'right: 0;'
  }

  li {
    padding: 0.6667rem 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.889rem;

    :hover {
      background-color: #F2F2F2;
    }

    svg {
      margin-left: 20px;
    }
  }
`;