import { usePath } from 'raviger';
import { Layout, IconContainer, TitleContainer } from './style';
import Link from '../../atoms/link';

const NavEntry = ({ icon: Icon, title, basePath, href, exact }) => {
  const path = usePath(basePath);

  const isActive = (exact && path === href) || path.includes(href);

  const color = isActive ? '#BE0F34' : 'black';

  return (
    <Link basePath={basePath} href={href}>
      <Layout>
        <IconContainer>
          <Icon fill={color} />
        </IconContainer>
        <TitleContainer style={{ '--color': color }}>
          {title}
        </TitleContainer>
      </Layout>
    </Link>
  );
};

export default NavEntry;