import { useRoutes } from 'raviger';
import router, { basePath } from './router';
import { Layout, Header, Content, Footer } from './style';
import Navbar from '../../components/organisms/navbar';
import Copyright from '../../components/organisms/copyright';

const Dashboard = () => {
  const routes = useRoutes(router, { basePath });

  return (
    <Layout>
      <Header>
        <Navbar />
      </Header>
      <Content>
        {routes}
      </Content>
      <Footer>
        <Copyright />
      </Footer>
    </Layout>
  );
};

export default Dashboard;