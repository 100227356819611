import Api from '../index';
import Default from '../default';

const route = '/greenscore-product';

const greenscoreApi = {
  createSite(greenscoreProductId, object) {
		return Api.post(`${route}/${greenscoreProductId}/greenscore-site`, object);
  },

  findSite(greenscoreProductId, params) {
    return Api.get(`${route}/${greenscoreProductId}/greenscore-site`, { params });
  },

  ...Default(route)
};

export default greenscoreApi;