import { Layout } from './style';
import Empty from './empty';
import { useState, useEffect } from 'react';
import CertificationsList from './certificationsList';
import moment from 'moment';
import { useQueryParams } from 'raviger';
import certificationApi from '../../../api/certification';

const Home = () => {
  const [{page}] = useQueryParams();

  useEffect(() => {
    certificationApi.find({ order: '-id', page: page || 1, limit: 6, pagination: true })
      .then(res => {
        const nativeData = res?.data?.rows || [];
        const adaptedData = nativeData.map(cert => ({
          id: cert.id,
          user: `${cert?.user.firstName} ${cert?.user.lastName}`,
          state: cert.state,
          commercialName: cert?.certificationMaterial?.greenscoreProduct?.commercialName,
          quantity: cert?.certificationMaterial?.quantity,
          codeSupplier: cert?.certificationMaterial?.codeSupplier,
          createdAt: cert.creationDate && moment(cert.creationDate).format('DD/MM/YYYY HH:mm'),
          certifiedAt: cert.certificationDate && moment(cert.certificationDate).format('DD/MM/YYYY HH:mm')
        }));
        const pagination = res?.data?.pagination;
        setCertifications(adaptedData);
        setIsLoading(false);
        setPagesNumber(pagination?.total?.page);
        setcurrentPage(pagination?.current?.page);
      });
  }, [page]);

  const [certifications, setCertifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  return (
    <Layout>
      { !isLoading
      ? certifications.length === 0
      ? <Empty />
      : <CertificationsList certifications={certifications} pagesNumber={pagesNumber} currentPage={currentPage}/>
      : ''}
    </Layout>
  );
};

export default Home;