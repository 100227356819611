import Styled from 'styled-components';

export const Layout = Styled.div`
  height: 110px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F2F2F2;
  font-size: 0.78rem;
`;
export const Brand = Styled.div``;
export const NavGroup = Styled.div`
  &:not(:last-child) {
    margin-right: 60px;
  }
`;
export const Nav = Styled.div`
  display: flex;
`;
export const UserGroup = Styled.div`
  display: flex;
  align-items: center;
`;
export const UserEntity = Styled.div``;
export const User = Styled.div`
  display: flex;
  align-items: center;
  margin-left: 70px;
`;
export const UserName = Styled.div`
  margin-right: 11px;
  line-height: 0.9;
`;

export const LanguagePickerContainer = Styled.div`
  margin-left: 70px;
`;