import Styled from 'styled-components';

export const Layout = Styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const Flag = Styled.img`
  width: 38px;
  height: 20px;
`;