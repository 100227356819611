import Styled from 'styled-components';

export const Layout = Styled.div`
  width: 70%;
  padding-bottom: 20px;
`;

export const InputGroup = Styled.div`
  margin-bottom: 30px;
`;

export const ButtonContainer = Styled.div`
  display: flex;
  justify-content: center;
  button {
    width: 80%;
  }
`;

export const Text = Styled.div`
  font-size: 1.333rem;
  line-height: 1.278rem;
  display: flex;
  align-items: center;
  color: #BE0F34;
  margin-bottom: 30px;
`;

