
const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  role: 0,
  token: '',
  entity: {
    name: ''
  },
  error: '',
  hasUser: false
};

const user = (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        ...payload,
        hasUser: true
      };
    case 'SIGN_OUT':
      return {
        ...initialState
      };
    case 'CHECK_LOCAL_AUTH':
      return {
        ...state,
        ...payload,
        hasUser: true
      };
    default:
      return state;
  }
};

export default user;