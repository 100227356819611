import { useTranslation } from 'react-i18next';
import { Select } from './style';
import InputText from '../input-text';

const InputSelect = ({ values = [], readOnly, ...inputProps }) => {
  const { t } = useTranslation();
  if (readOnly) {
    // this time we want this to auto cast
    const found = values.find(value => value.value == inputProps.value); //eslint-disable-line eqeqeq
    const text = found?.text || inputProps.value || '';

    return (
      <InputText value={text} readOnly />
    );
  }

  return (
    <Select { ...inputProps }>
      <option value={0} disabled>{t('common.form.selectOption')}</option>
      {values.map(({value, text}) => (
        <option value={value} key={value}>
          {text}
        </option>
      ))}
    </Select>
  );
};

export default InputSelect;