import Styled from 'styled-components';

export const Layout = Styled.div`
  margin-left: 80px;
  display: flex;
`;

export const StepNumber = Styled.div`
  font-weight: bold;
  font-size: 2.667rem;
  line-height: 3rem;
  color: #F2F2F2;
`;

export const TextContainer = Styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5556rem;
`;

export const FirstText = Styled.div`
  font-size: 0.7778rem;
`;

export const SecondText = Styled.div`
  font-size: 1.556rem;
  font-weight: bold;
  line-height: 1.556rem;
  color: #BE0F34;
`;





