import Styled from 'styled-components';

const TextUnderline = Styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
  font-size: ${({size}) => size}px;
  color: ${({color}) => color};
`;

export default TextUnderline;