import Styled from 'styled-components';

export const Layout = Styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Header = Styled.header``;
export const Content = Styled.div`
  flex: 1;
  display: flex;
`;
export const Footer = Styled.footer``;