import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { toast } from 'react-toastify';

const useNotification = ({text, type = 'success'}) => {

  const NotifBody = () => (
    <>
      {type === 'success' && <SuccessIcon/>}
      { text }
    </>
  );

  const showNotification = () => {
    toast(<NotifBody/>, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
      });
  };

  return [
    showNotification
  ];
};

export default useNotification;