import Styled from 'styled-components';

export const Overlay = Styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = Styled.div`
  display: flex;
  flex-direction: column;
  width: 725px;
  background: #fff;
`;

export const Header = Styled.div`
  width: 100%;
  height: 100px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = Styled.div`
  font-weight: bold;
  font-size: 1.333rem;
`;

export const Close = Styled.div`
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
`;

export const Body = Styled.div`
  display: flex;
  justify-content: center;
`;