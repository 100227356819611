import Api from '../index';
import Default from '../default';

const route = '/certification';

const certificationApi = {
  createMaterial(body) {
		return Api.post(`${route}/material`, body);
  },
  createPlant(id, body) {
		return Api.post(`${route}/${id}/plant`, body);
  },
  createQuantity(id, body) {
		return Api.post(`${route}/${id}/quantity`, body);
  },
  createConfirm(id) {
		return Api.post(`${route}/${id}/confirm`, {});
  },
  updateMaterial(id, body) {
		return Api.patch(`${route}/${id}/material`, body);
  },
  updatePlant(id, body) {
		return Api.patch(`${route}/${id}/plant`, body);
  },
  updateQuantity(id, body) {
		return Api.patch(`${route}/${id}/quantity`, body);
  },
  ...Default(route)
};

export default certificationApi;