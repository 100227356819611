import Styled from 'styled-components';

export const Layout = Styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const Brand = Styled.div``;
export const Container = Styled.div`
  display: flex;
  //width: 62%;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.05);
`;
export const Side = Styled.div`
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Form = Styled.div`
  height: 100%;
  padding: 46px 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const InputGroup = Styled.div``;
export const InputForm = Styled.div`
  ${InputGroup}:not(:last-child) {
    margin-bottom: 40px;
  }
`;
export const SubmitForm = Styled.div``;
export const SubmitContainer = Styled.div`
  margin-bottom: 20px;
`;