import Styled from 'styled-components';

const Button = Styled.button`
  width: 100%;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  background-color: #BE0F34;
  border-radius: 16px;
  padding: 24px 21px;
  line-height: 0.9;
  cursor: pointer;
`;

export default Button;