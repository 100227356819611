import { Layout, ButtonsContainer } from './style';
import Text from '../../../../components/atoms/text';
import Button from '../../../../components/atoms/button';
import { ReactComponent as AgricultureIcon } from '../../../../assets/icons/agriculture.svg';
import { ReactComponent as DashboardIcon } from '../../../../assets/icons/dashboard.svg';
import { useTranslation } from 'react-i18next';
import { navigate } from 'raviger';

const Validation = () => {

  const { t } = useTranslation();

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  const goToCertification = () => {
    navigate('/dashboard/certification');
  };

  return (
    <Layout>
      <Text
        style={{
          '--fontSize': '3.611rem'
        }}
      >
        {t('certification.validation.text')}
      </Text>
      <ButtonsContainer>
        <Button onClick={goToDashboard}>
          <DashboardIcon fill="#fff" /> {t('certification.validation.backButton')}
        </Button>
        <Button onClick={goToCertification}>
          <AgricultureIcon fill="#fff" /> {t('common.certificate')}
        </Button>
      </ButtonsContainer>
    </Layout>
  );
};

export default Validation;