import StepForm1 from '../stepForm1';
import StepForm2 from '../stepForm2';
import StepForm3 from '../stepForm3';
import { useTranslation } from 'react-i18next';
import { navigate } from 'raviger';
import { Layout, Title, ButtonContainer } from './style';
import Button from '../../../../components/atoms/button';
import certificationApi from '../../../../api/certification';

import useNotification from '../../../../hooks/useNotification';

const StepForm4 = ({ certificationData }) => {
  const { t } = useTranslation();

  const [showNotification] = useNotification({
    text: t('certification.validation.successNotification')
  });

  const handleValidate = async event => {
    event.preventDefault();
    try {
      const response = await certificationApi.createConfirm(certificationData?.id);
      if (response?.status === 200) {
        showNotification();
        navigate('/dashboard/validation');
      }
    } catch (error) {
      // todo show notification error
    }
  };

  return (
    <Layout>
      <Title>
        {t('certification.step4.titleStep1')}
      </Title>
      <StepForm1 certificationData={certificationData} readOnly />
      {
        certificationData?.certificationPlant?.length > 0 && (
          <>
            <Title>
              {t('certification.step4.titleStep2')}
            </Title>
            <StepForm2 certificationData={certificationData} readOnly />
          </>
        )
      }
      <Title>
        {t('certification.step4.titleStep3')}
      </Title>
      <StepForm3 certificationData={certificationData} readOnly />
      {
        certificationData?.state < 5
        && (
          <ButtonContainer>
            <Button onClick={handleValidate}>
              {t('common.form.validate')}
            </Button>
          </ButtonContainer>
        )
      }
    </Layout>
  );
};

export default StepForm4;