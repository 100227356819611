import { useEffect, useCallback } from 'react';
import InputTextLabeled from '../../../../components/molecules/input-text-labeled';
import Modal from '../../../../components/molecules/modal';
import TextUnderline from '../../../../components/atoms/text-undeline';
import Button from '../../../../components/atoms/button';
import AddProductionForm from '../addProductionForm';
import { Layout, InputGroup, SelectContainer, ButtonContainer} from './style';
import { useTranslation } from 'react-i18next';
import { useInput } from '../../../../hooks';
import { useQueryParams } from 'raviger';
import { useState } from 'react';
import InputSelectLabeled from '../../../../components/molecules/input-select-labeled';
import CertificationApi from '../../../../api/certification';
import GreenscoreProductApi from '../../../../api/greenscore-product';
import moment from 'moment';

const StepForm1 = ({ duplicationData, certificationData, setLocalState, readOnly = false }) => {
  const { t } = useTranslation();

  let initialFormData;

  if ((!certificationData || certificationData.state <= 1) && duplicationData?.certificationMaterial) {
    initialFormData = {
      ...duplicationData.certificationMaterial
    };

    delete initialFormData.codeOrder;
    delete initialFormData.movementExpedition;
    delete initialFormData.quantity;
  }
  else if (certificationData?.certificationMaterial) {
    initialFormData = certificationData.certificationMaterial;
  }
  else {
    initialFormData = {
      codeSupplier: '',
      isBIO: false,
      isVegan: false,
      isFair: false,
      fairnessContract: false,
      movementFabrication: '',
      greenscoreProductId: 0,
      greenscoreSiteId: 0
    };
  }

  const booleanValues = [{ text: t('common.form.yes'), value: true }, { text: t('common.form.no'), value: false }];
  const [formData, setFormData, setFormDataOnChange] = useInput(initialFormData);
  const [greenscoreProduct, setGreenscoreProduct] = useState([]);
  const [greenscoreSite, setGreenscoreSite] = useState([]);
  const [query, setQuery] = useQueryParams();

  const [showModal, setShowModal] = useState(false);

  const refreshProduct = useCallback(
    () => {
      GreenscoreProductApi.find({ 'where.type': 'material', order: 'commercialName' })
        .then(response => {
          setGreenscoreProduct(response?.data?.rows || []);
        });
    },
    []
  );

  const refreshSite = useCallback(
    () => {
      GreenscoreProductApi.findSite(formData.greenscoreProductId, {})
        .then(response => {
          setGreenscoreSite(response?.data?.rows || []);
        });
    },
    [formData.greenscoreProductId]
  );

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      let response;

      if (certificationData?.state > 1) {
        response = await CertificationApi.updateMaterial(certificationData?.id, formData);
      } else {
        response = await CertificationApi.createMaterial(formData);
        if (response?.data?.id) setQuery({ ...query, id: response.data.id });
      }

      setLocalState(current => current + 1);
    } catch (error) {
      // todo show notification error
    }
  };

  const handleSubmitModal = modalData => async event => {
    event.preventDefault();

    if (formData.greenscoreProductId <= 0) return;

    await GreenscoreProductApi.createSite(formData.greenscoreProductId, modalData);

    refreshSite();

    setShowModal(false);
  };

  // refresh products
  useEffect(
    () => {
      refreshProduct();
    },
    [refreshProduct]
  );

  // if product is selected, refresh sites for product
  useEffect(
    () => {
      if (formData.greenscoreProductId <= 0) return;

      refreshSite();
    },
    [formData.greenscoreProductId, refreshSite, setFormData]
  );

  const onProductChange = event => {
    setFormDataOnChange(event);

    setFormData(current => ({
      ...current,
      greenscoreSiteId: 0,
      isBIO: false,
      isVegan: false,
      isFair: false,
      fairnessContract: false
    }));
  };

  const onSiteChange = event => {
    setFormDataOnChange(event);

    const selected = greenscoreSite.find(site => site.id === parseInt(formData.greenscoreSiteId));

    setFormData(current => ({
      ...current,
      isBIO: selected?.isBIO || false,
      isVegan: selected?.isVegan || false,
      isFair: selected?.isFair || false,
      fairnessContract: selected?.fairnessContract || false
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Layout>
          <SelectContainer>
            <InputSelectLabeled
              value={formData.greenscoreProductId}
              values={greenscoreProduct.map(product => ({ text: product.commercialName, value: product.id }))}
              label={ t('certification.step1.form.commercialName.label') }
              isMandatory
              name="greenscoreProductId"
              onChange={onProductChange}
              readOnly={readOnly}
            />
          </SelectContainer>
          <InputGroup>
            <InputTextLabeled
              value={formData.codeSupplier}
              label={t('certification.step1.form.batchNumber.label')}
              isMandatory
              type="text"
              name="codeSupplier"
              onChange={setFormDataOnChange}
              readOnly={readOnly}
            />
          </InputGroup>
          <SelectContainer>
            <InputSelectLabeled
              value={formData.isBIO}
              values={booleanValues}
              label={ t('certification.step1.form.bioCertified.label') }
              isMandatory
              name="isBIO"
              onChange={setFormDataOnChange}
              readOnly={readOnly}
            />
          </SelectContainer>
          <SelectContainer>
            <InputSelectLabeled
              value={formData.greenscoreProductId}
              values={greenscoreProduct.map(product => ({ text: product.code, value: product.id }))}
              label={ t('certification.step1.form.internalCode.label') }
              isMandatory
              name="greenscoreProductId"
              onChange={setFormDataOnChange}
              readOnly
            />
          </SelectContainer>
          <InputGroup>
            <InputTextLabeled
              value={formData.movementFabrication && moment(formData.movementFabrication).format('YYYY-MM-DD')}
              label={t('certification.step1.form.prodactionDate.label')}
              isMandatory
              type="date"
              name="movementFabrication"
              onChange={setFormDataOnChange}
              readOnly={readOnly}
            />
          </InputGroup>
          <SelectContainer>
            <InputSelectLabeled
              value={formData.isVegan}
              values={booleanValues}
              label={ t('certification.step1.form.veganCertified.label') }
              isMandatory
              name="isVegan"
              onChange={setFormDataOnChange}
              readOnly={readOnly}
            />
          </SelectContainer>
          <SelectContainer>
            <InputSelectLabeled
              value={formData.greenscoreProductId}
              values={greenscoreProduct.map(product => ({ text: product.name, value: product.id }))}
              label={ t('certification.step1.form.internalDesignation.label') }
              isMandatory
              name="greenscoreProductId"
              onChange={setFormDataOnChange}
              readOnly
            />
          </SelectContainer>
          <SelectContainer>
            <InputSelectLabeled
              value={formData.greenscoreSiteId}
              values={greenscoreSite.map(site => ({ text: site.name, value: site.id }))}
              label={ t('certification.step1.form.productionSite.label') }
              isMandatory
              name="greenscoreSiteId"
              onChange={onSiteChange}
              readOnly={readOnly}
            />
          </SelectContainer>
          <SelectContainer>
            <InputSelectLabeled
              value={formData.isFair}
              values={booleanValues}
              label={ t('certification.step1.form.fairCertified.label') }
              isMandatory
              name="isFair"
              onChange={setFormDataOnChange}
              readOnly={readOnly}
            />
          </SelectContainer>
          <SelectContainer>
            <InputSelectLabeled
              value={formData.greenscoreProductId}
              values={greenscoreProduct.map(product => ({ text: product.category, value: product.id }))}
              label={ t('certification.step1.form.category.label') }
              isMandatory
              name="greenscoreProductId"
              onChange={setFormDataOnChange}
              readOnly
            />
          </SelectContainer>
          <SelectContainer>
            <InputSelectLabeled
              value={formData.greenscoreSiteId}
              values={greenscoreSite.map(site => ({ text: `${site.address || ''} ${site.city} ${site.country}`, value: site.id }))}
              label={ t('certification.step1.form.productionAddress.label') }
              isMandatory
              name="greenscoreSiteId"
              onChange={setFormDataOnChange}
              readOnly
            />
            {!readOnly &&<TextUnderline size="14" color="#008DC9" onClick={() => setShowModal(true)}>
                {t('certification.addProductionForm.title')}
              </TextUnderline>
            }
          </SelectContainer>
          <SelectContainer>
            <InputSelectLabeled
              value={formData.fairnessContract}
              values={booleanValues}
              label={ t('certification.step1.form.fairPrime.label') }
              isMandatory
              name="fairnessContract"
              onChange={setFormDataOnChange}
              readOnly={readOnly}
            />
          </SelectContainer>
        </Layout>

        { !readOnly && <ButtonContainer>
            <Button type="submit">
              {t('common.form.next')}
            </Button>
          </ButtonContainer>
        }

      </form>

      { !readOnly && <Modal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        title={t('certification.addProductionForm.title')}
        >
          <AddProductionForm
            handleSubmit={handleSubmitModal}
          />
        </Modal>
      }
    </>
  );
};

export default StepForm1;