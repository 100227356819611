import { useTranslation } from 'react-i18next';
import { useInput } from '../../../../../hooks';
import { useEffect } from 'react';
import moment from 'moment';
import { Layout, InputGroup, SelectContainer, Title, BatchForm, MultiInputGroup, OriginForm, SecondTitle } from './style';
import InputSelectLabeled from '../../../../../components/molecules/input-select-labeled';
import InputTextLabeled from '../../../../../components/molecules/input-text-labeled';
import Text from '../../../../../components/atoms/text';
import MultipleInputLabeled from '../../../../../components/molecules/multiple-input-labeled';
import InputText from '../../../../../components/atoms/input-text';
// import AddCooperativeForm from '../../addCooperativeForm';
// import Modal from '../../../../../components/molecules/modal';
// import TextUnderline from '../../../../../components/atoms/text-undeline';
// import GreenscoreProductApi from '../../../../../api/greenscore-product';

const PlantForm = ({ formIndex, greenscoreProduct, formData, setFormData, readOnly }) => {
  const { t } = useTranslation();
  const [plantFormData, setPlantFormData, setPlantFormDataOnChange] = useInput(formData);
  const booleanValues = [{text: t('common.form.yes'), value: true}, {text: t('common.form.no'), value: false}];

  const handleDeleteBatch = () => {
    setFormData(prevFormData => {
      const data = [...prevFormData];
      data.splice(formIndex, 1);
      return data;
    });
  };

  useEffect(
    () => {
      setPlantFormData(formData);
    },
    [formData, setPlantFormData]
  );

  useEffect(() => {
    setFormData(prevFormData => {
      const data = [...prevFormData];
      data[formIndex] = plantFormData;
      return data;
    });
  }, [plantFormData, formIndex, setFormData]);

  return (
    <Layout>
      <Title>
        <div>{t('certification.step2.plantTitle')} <SecondTitle>{greenscoreProduct.find(product => parseInt(plantFormData.greenscoreProductId) === product.id)?.botanicalName}</SecondTitle></div>
        {
          !readOnly && (
            <Text
              style={{
                '--fontSize': '0.7778rem',
                '--color': '#BE0F34'
              }}
              onClick={handleDeleteBatch}
            >
              {t('certification.step2.deleteBatch')}
            </Text>
          )
        }
      </Title>
      <BatchForm>
        <SelectContainer>
          <InputSelectLabeled
            value={plantFormData.greenscoreProductId}
            values={greenscoreProduct.map(product => ({ text: product.inciName, value: product.id }))}
            label={ t('certification.step2.form.productName.label') }
            isMandatory
            name="greenscoreProductId"
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
        </SelectContainer>
        <InputGroup>
          <InputTextLabeled
            value={plantFormData.movementReception && moment(plantFormData.movementReception).format('YYYY-MM-DD')}
            label={t('certification.step2.form.receptionDate.label')}
            type="date"
            name="movementReception"
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
        </InputGroup>
        <InputGroup>
          <InputTextLabeled
            value={plantFormData.codeSupplier}
            label={t('certification.step2.form.batchNumber.label')}
            type="text"
            name="codeSupplier"
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
        </InputGroup>
        <MultipleInputLabeled
          label={t('certification.step2.form.harvestPeriod.label')}
          tooltip={t('certification.step2.form.harvestPeriod.tooltip')}
          isMandatory
        >
          <MultiInputGroup>
          <InputText
            value={plantFormData.movementHarvestFrom && moment(plantFormData.movementHarvestFrom).format('YYYY-MM-DD')}
            type="date"
            name="movementHarvestFrom"
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
          <span>{ t('common.form.toPeriod') }</span>
          <InputText
            defaultValue={plantFormData.movementHarvestTo && moment(plantFormData.movementHarvestTo).format('YYYY-MM-DD')}
            type="date"
            name="movementHarvestTo"
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
          </MultiInputGroup>
        </MultipleInputLabeled>
      </BatchForm>
      <Title style={{marginBottom: '40px'}}>{ t('certification.step2.originTitle') }</Title>
      <OriginForm>
        <InputGroup>
          <InputTextLabeled
            value={plantFormData?.greenscoreSite?.country}
            label={ t('certification.step2.form.origin.country.label') }
            type="text"
            name="greenscoreSite.country"
            isMandatory
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
        </InputGroup>
        <InputGroup>
          <InputTextLabeled
            value={plantFormData?.greenscoreSite?.city}
            label={ t('certification.step2.form.origin.region.label') }
            type="text"
            name="greenscoreSite.city"
            isMandatory
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
        </InputGroup>
        <InputGroup>
          <InputTextLabeled
            value={plantFormData?.greenscoreSite?.address}
            label={ t('certification.step2.form.origin.address.label') }
            type="text"
            name="greenscoreSite.address"
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
        </InputGroup>
        <InputGroup>
          <InputTextLabeled
            value={plantFormData?.greenscoreSite?.name}
            label={ t('certification.step2.form.origin.name.label') }
            type="text"
            name="greenscoreSite.name"
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
        </InputGroup>
      </OriginForm>
      <BatchForm>
        <SelectContainer>
          <InputSelectLabeled
            value={plantFormData.isBIO}
            values={booleanValues}
            label={ t('certification.step2.form.bioCertified.label') }
            isMandatory
            name="isBIO"
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelectLabeled
            value={plantFormData.isFair}
            values={booleanValues}
            label={ t('certification.step2.form.fairCertified.label') }
            isMandatory
            name="isFair"
            onChange={setPlantFormDataOnChange}
            readOnly={readOnly}
          />
        </SelectContainer>
      </BatchForm>
      {/* {!readOnly &&<TextUnderline size="14" color="#008DC9" onClick={() => setShowModal(true)}>
          {t('certification.addCooperativeForm.title')}
        </TextUnderline>
      } */}
      {/* {!readOnly && <Modal
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          title={t('certification.addCooperativeForm.title')}
        >
          <AddCooperativeForm
            handleSubmit={handleSubmitModal}
          />
        </Modal>
      } */}
    </Layout>
  );
};

export default PlantForm;