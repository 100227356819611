import I18n from 'i18next';

export const changeLanguage = short => async dispatch => {
  await I18n.changeLanguage(short);

  localStorage.setItem('language', short);

  dispatch({ type: 'CHANGE_LANGUAGE', payload: { short } });
};

export const checkLocalLanguage = async dispatch => {
  const short = localStorage.getItem('language');

  if (!short) return;

  await I18n.changeLanguage(short);

  dispatch({ type: 'CHECK_LOCAL_LANGUAGE', payload: { short } });
};