import Styled from 'styled-components';

export const Layout = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const IconContainer = Styled.div`
  margin-bottom: 15px;
`;
export const TitleContainer = Styled.div`
  color: var(--color, black);
`;