import { Layout, StepIcon, TextContainer } from './style';

const StepMenuEntry = ({ step, text, stepNumber, stepReach, handleStepClick }) => {
  const isClickable = step && step <= stepReach;
  const active = stepNumber === step;

  const onClick = () => {
    if (isClickable) {
      handleStepClick(step);
    }
  };

  return (
    <Layout
      onClick={onClick}
      style={isClickable ? { cursor: 'pointer' } : {}}
    >
      <StepIcon style={active ? { backgroundColor: '#BE0F34' } : {}} />
      <TextContainer style={active ? { color: '#000' } : {}}>
        {text}
      </TextContainer>
    </Layout>
  );
};

export default StepMenuEntry;