import Api from '../../api/user';

export const signIn = ({ email, password }) => async dispatch => {
  const response = await Api.signIn({ email, password });
  const user = response.data;

  localStorage.setItem('user', JSON.stringify(user));

  Api.applyToken(user.token);

  dispatch({ type: 'SIGN_IN', payload: user });
};

export const signOut = dispatch => {
  localStorage.removeItem('user');

  Api.applyToken();

  dispatch({ type: 'SIGN_OUT' });
};

export const checkLocalAuth = dispatch => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (!user) return;

  Api.applyToken(user.token);

  dispatch({ type: 'CHECK_LOCAL_AUTH', payload: user });
};