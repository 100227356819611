import Styled from 'styled-components';

import SelectArrowIcon from '../../../assets/icons/arrow.png';

export const Select = Styled.select`
  width: 100%;
  padding: 19px 35px 19px 21px;
  outline-color: #BE0F34;
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  appearance: none;
  background: url(${SelectArrowIcon}) no-repeat 95% 50% #F2F2F2 !important;
`;