import { Layout } from './style';
import Text from '../../atoms/text';

const Copyright = () => {
  return (
    <Layout>
      <Text
        style={{
          '--lineHeight': 0.9
        }}
      >
        ©Clarins - Communify copyright
      </Text>
    </Layout>
  );
};

export default Copyright;