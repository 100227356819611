import Styled from 'styled-components';
import ValidationImage1 from '../../../../assets/images/validation-1.png';
import ValidationImage2 from '../../../../assets/images/validation-2.png';

export const Layout = Styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${ValidationImage1}), url(${ValidationImage2});
  background-position: bottom left, right bottom;
  background-repeat: no-repeat;
`;

export const ButtonsContainer = Styled.div`
  display: flex;
  width: 50%;
  margin-top: 30px;

  > button:first-child {
    margin-right: 50px;
    svg {
      margin-right: 10px;
    }
  }
`;
