import Styled from 'styled-components';

export const Layout = Styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 56px;
  row-gap: 56px;
  margin: 50px 0 10px 0;
`;

export const InputGroup = Styled.div``;

export const SelectContainer = Styled.div``;

export const ButtonContainer = Styled.div`
  width: 30%;
  margin-left: auto;
`;

