import Styled from 'styled-components';

const InputText = Styled.input.attrs(
  props => {
    const { type } = props;

    if (!type) return props;

    if (!['email', 'password', 'text', 'date'].includes(type))
      throw new Error('wrong text input type');

    return props;
  })`
    width: 100%;
    border: none;
    background-color: #F2F2F2;
    border-radius: 4px;
    outline-color: #BE0F34;
    padding: 19px 21px;
    font-size: 0.9rem;

    ::placeholder {
      color: #A3A3A3;
      opacity: 1;
    }
  `;

export default InputText;