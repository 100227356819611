import InputTextLabeled from '../../../../components/molecules/input-text-labeled';
import Button from '../../../../components/atoms/button';
import { Layout, InputGroup, ButtonContainer } from './style';
import { useTranslation } from 'react-i18next';
import { useInput } from '../../../../hooks';
import certificationApi from '../../../../api/certification';
import moment from 'moment';

const StepForm3 = ({ duplicationData, certificationData, setLocalState, readOnly = false }) => {

  const { t } = useTranslation();
  // const initialFormData = {
  //   ...certificationData?.certificationMaterial,
  //   quantity: certificationData?.certificationMaterial?.quantity || '',
  //   codeOrder: certificationData?.certificationMaterial?.codeOrder || '',
  //   movementExpedition: certificationData?.certificationMaterial?.movementExpedition || ''
  // };

  let initialFormData;

  if (certificationData.state <= 3 && duplicationData) {
    initialFormData = {
      codeOrder: '',
      quantity: '',
      movementExpedition: ''
    };
  }
  else {
    initialFormData = {
      codeOrder: certificationData?.certificationMaterial?.codeOrder || '',
      quantity: certificationData?.certificationMaterial?.quantity || '',
      movementExpedition: certificationData?.certificationMaterial?.movementExpedition || ''
    };
  }

  const [formData, , setFormDataOnChange] = useInput(initialFormData);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      if (certificationData?.state > 3) {
        await certificationApi.updateQuantity(certificationData?.id, formData);
      } else {
        await certificationApi.createQuantity(certificationData?.id, formData);
      }

      setLocalState(current => current + 1);
    } catch (error) {
      // todo show notification error
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <Layout>
        <InputGroup>
          <InputTextLabeled
            value={formData.movementExpedition && moment(formData.movementExpedition).format('YYYY-MM-DD')}
            label={t('certification.step3.form.movementExpedition.label')}
            isMandatory
            type="date"
            name="movementExpedition"
            onChange={setFormDataOnChange}
            readOnly={readOnly}
          />
        </InputGroup>
        <InputGroup>
          <InputTextLabeled
            value={formData.codeOrder}
            label={t('certification.step3.form.batchPurchaseOrder.label')}
            isMandatory
            type="text"
            name="codeOrder"
            onChange={setFormDataOnChange}
            readOnly={readOnly}
          />
        </InputGroup>
        <InputGroup>
          <InputTextLabeled
            value={formData.quantity}
            label={t('certification.step3.form.batchQuantity.label')}
            isMandatory
            type="text"
            name="quantity"
            onChange={setFormDataOnChange}
            readOnly={readOnly}
          />
        </InputGroup>

      </Layout>

      {
        !readOnly && <ButtonContainer>
          <Button type="submit">
            {t('common.form.next')}
          </Button>
        </ButtonContainer>
      }

    </form>
  );
};

export default StepForm3;