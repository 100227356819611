import { useState } from 'react';
import { Layout, Content } from './style';

const Tooltip = ({ parent: Parent, children: Children }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Layout
      onMouseEnter={() => setToggle(true)}
      onMouseLeave={() => setToggle(false)}
    >
      <Parent />
      {
        toggle && (
          <Content>
            <Children />
          </Content>
        )
      }
    </Layout>
  );
};

export default Tooltip;