import { useState } from 'react';

const useInput = base => {
  const [value, setValue] = useState(base);

  return [
    value,
    setValue,
    event => {
      const inputKey = event.target.name;
      const inputValue = event.target.value;

      const keys = inputKey
        .split('.');

      let level = value;

      for (let i = 0; i < keys.length; ++i) {
        if (!level[keys[i]]) {
          level[keys[i]] = {};
        };
        if (i === keys.length - 1) {
          level[keys[i]] = ['true', 'false'].includes(inputValue) ? JSON.parse(inputValue) : inputValue;
        }
        level = level[keys[i]];
      }

      setValue(
        {
          ...value
        }
      );
    }
  ];
};

export default useInput;