import Styled from 'styled-components';
import DashboardGridImage1 from '../../../assets/images/dashboard-grid-1.png';
import DashboardGridImage2 from '../../../assets/images/dashboard-grid-2.png';

export const Layout = Styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  background-image: url(${DashboardGridImage1}), url(${DashboardGridImage2});
  background-position: top left, right bottom;
  background-repeat: no-repeat;
  padding: 50px 0;
`;

export const Container = Styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 550px)
`;
