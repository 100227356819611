import { Layout, StepNumber, TextContainer, FirstText, SecondText } from './style';
import { useTranslation } from 'react-i18next';

const StepTitle = ({ stepNumber, certificationData }) => {

  const { t } = useTranslation();
  const getTitle = () => {
    const commercialName = certificationData.certificationMaterial?.greenscoreProduct?.commercialName || '';
    const materialBatchCode = certificationData.certificationMaterial?.codeSupplier ? `: ${certificationData.certificationMaterial.codeSupplier}` : '';

    return `${commercialName} ${materialBatchCode}`;
  };

  return (
    <Layout>
      <StepNumber>{`0${stepNumber}.`}</StepNumber>
      <TextContainer>
        <FirstText>{t(`certification.step${stepNumber}.firstTitle`)}</FirstText>
        <SecondText>
          {
            stepNumber === 2
            ? getTitle()
            : t(`certification.step${stepNumber}.secondTitle`)
          }
        </SecondText>
      </TextContainer>
    </Layout>
  );
};


export default StepTitle;