import { useSelector } from 'react-redux';
import { FiChevronDown } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { Layout, Brand, Nav, NavGroup, User, UserName, UserGroup, UserEntity, LanguagePickerContainer } from './style';
import Image from '../../atoms/image';
import NavEntry from '../../molecules/nav-entry';
import Dropdown from '../../molecules/dropdown';
import Link from '../../atoms/link';
import LanguagePicker from '../../molecules/language-picker';
import { ReactComponent as DashboardIcon } from '../../../assets/icons/dashboard.svg';
import { ReactComponent as AgricultureIcon } from '../../../assets/icons/agriculture.svg';
import LogoClarins from '../../../assets/logos/clarins.png';

const Navbar = () => {
  const { t } = useTranslation();

  const user = useSelector(state => state.user);
  const currentEntity = user?.entity;
  const currentUserName = `${user.firstName} ${user.lastName}`;

  return (
    <Layout>
      <Brand>
        <Image
          src={LogoClarins}
          style={{ '--width': '180px' }}
        />
      </Brand>
      <Nav>
        <NavGroup>
          <NavEntry
            icon={DashboardIcon}
            title="Dashboard"
            basePath="/dashboard"
            href="/home"
          />
        </NavGroup>
        <NavGroup>
          <NavEntry
            icon={AgricultureIcon}
            title="Certification"
            basePath="/dashboard"
            href="/certification"
          />
        </NavGroup>
      </Nav>
      <UserGroup>
        <UserEntity>
          {currentEntity?.name} - {currentEntity?.code}
        </UserEntity>
        <Dropdown
          parent={
            () => (
              <User>
                <UserName>
                  {currentUserName}
                </UserName>
                <FiChevronDown />
              </User>
            )
          }
          children={
            () => (
              <>
                <Link basePath="/dashboard" href="/settings"><li>{t('navBar.settings')}</li></Link>
                <Link basePath="/dashboard" href="/signout"><li>{t('navBar.disconnect')}</li></Link>
              </>
            )
          }
          anchor="right"
        />
        <LanguagePickerContainer>
          <LanguagePicker />
        </LanguagePickerContainer>
      </UserGroup>
    </Layout>
  );
};

export default Navbar;