import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import I18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Store from './redux';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

import 'react-toastify/dist/ReactToastify.css';

import GlobalStyle from './components/atoms/global-style';
import Pages from './pages';
import { checkLocalAuth } from './redux/user';
import { checkLocalLanguage } from './redux/language';
import { fr, en } from './languages';
import { ToastContainer } from 'react-toastify';

I18n
  .use(initReactI18next)
  .init({
    resources: {
      fr,
      en
    },
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false
    }
  });

Store.dispatch(checkLocalAuth);
Store.dispatch(checkLocalLanguage);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ToastContainer />
    <Provider store={Store}>
      <Pages />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
