import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'raviger';
import { useInput } from '../../hooks';
import { signIn } from '../../redux/user';
import { Trans, useTranslation } from 'react-i18next';
import {
  Layout,
  Brand,
  Container,
  Side,
  Form,
  InputGroup,
  InputForm,
  SubmitForm,
  SubmitContainer
} from './style';
import Colorize from '../../components/atoms/colorize';
import Text from '../../components/atoms/text';
import InputTextLabeled from '../../components/molecules/input-text-labeled';
import Button from '../../components/atoms/button';
import Image from '../../components/atoms/image';
import Link from '../../components/atoms/link';
import LogoClarins from '../../assets/logos/clarins.png';
import ImageSignin from '../../assets/images/signin.png';

const Signin = () => {
  const { t } = useTranslation();
  const [credentials,, setCredentialsOnChange] = useInput({
    email: '',
    password: ''
  });
  const hasUser = useSelector(state => state.user.hasUser);
  const dispatch = useDispatch();
  const initSignIn = useCallback(
    () => {
      dispatch(signIn(credentials));
    },
    [credentials, dispatch]
  );
  useEffect(() => {
    const onEnter = event => {
      if (event.key === 'Enter') initSignIn();
    };

    document.addEventListener('keyup', onEnter);

    return () => { document.removeEventListener('keyup', onEnter); };
  }, [initSignIn]);

  if (hasUser) return <Redirect to="/dashboard" />;

  return (
    <Layout>
      <Brand>
        <Image src={LogoClarins} />
      </Brand>
      <Text>
        <Trans
          i18nKey="common.welcome"
          t={t}
        >
          Bienvenue sur <Colorize>l’outil de traçabilité</Colorize> du Groupe Clarins.
        </Trans>
      </Text>
      <Container>
        <Side>
          <Image src={ImageSignin} />
        </Side>
        <Side>
          <Form>
            <Text
              style={{
                '--fontWeight': 'normal',
                '--fontSize': '36px'
              }}
            >
              {t('common.connection')}
            </Text>
            <InputForm>
              <InputGroup>
                <InputTextLabeled
                  label={t('common.mailAddress')}
                  isMandatory
                  type="email"
                  placeholder="name@email.com"
                  name="email"
                  onChange={setCredentialsOnChange}
                />
              </InputGroup>
              <InputGroup>
                <InputTextLabeled
                  label={t('common.password')}
                  isMandatory
                  type="password"
                  placeholder="••••••••••••"
                  name="password"
                  onChange={setCredentialsOnChange}
                />
              </InputGroup>
            </InputForm>
            <SubmitForm>
              <SubmitContainer>
                <Button onClick={initSignIn}>{t('signIn.connect')}</Button>
              </SubmitContainer>
              <Link href="/signup">
                <Text
                  style={{
                    '--textAlign': 'center'
                  }}
                >
                  <Trans
                    i18nKey="signIn.noAccount"
                    t={t}
                  >
                    Pas de compte ? <Colorize>Cliquez ici</Colorize> pour en créer un
                  </Trans>
                </Text>
              </Link>
            </SubmitForm>
          </Form>
        </Side>
      </Container>
    </Layout>
  );
};

export default Signin;