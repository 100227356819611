import Label from '../../atoms/label';
import InputSelect from '../../atoms/input-select';
import Colorize from '../../atoms/colorize';

const InputSelectLabeled = ({ label, isMandatory, ...inputProps }) => {
  return (
    <Label>
      {label} {isMandatory && <Colorize>*</Colorize>}
      <InputSelect {...inputProps} />
    </Label>
  );
};

export default InputSelectLabeled;