import Styled from 'styled-components';

export const Layout = Styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

export const Title = Styled.div`
  display: flex;
  font-size: 1.333rem;
  font-weight: 300;
  margin-top: 70px;
`;

export const ButtonContainer = Styled.div`
  width: 30%;
  margin: 51px 0 0 auto;
`;

